
$maxWidth: 1080px;
@mixin displayCenter($maxWidth) {
	position: relative;
 	display: block;
 	margin: auto;
 	max-width: $maxWidth;
 	width: 100%;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin social-after($bgPosition){
	$url:"http://assets.ngin.com/site_files/8917/images/connect_white_large_hover.svg";
	content:"";
	display:block;
	position:absolute;
	background-image: url($url);
	height:80px;
	width:80px;
	top:0;
	z-index:1;
	background-position: $bgPosition;
	background-repeat: no-repeat;
	background-color:transparent;
}
@mixin social-before($bgPosition){
	$url:"http://assets.ngin.com/site_files/8917/images/connect_black_large.svg";
	content:"";
	display:block;
	position:absolute;
	background-image: url($url);
	height:40px;
	width:40px;
	top:0;
	z-index:1;
	background-position: $bgPosition;
	background-repeat: no-repeat;
	background-color:transparent;
}
@mixin skew($angle1, $angle2){
	-webkit-transform: skew($angle1, $angle2);
    -moz-transform: skew($angle1, $angle2);
    -o-transform: skew($angle1, $angle2);
    -ms-transform: skew($angle1, $angle2);
    transform: skew($angle1, $angle2);
}

$yellow: #ffbb30;
$black: #111111;
$white: #ffffff;
$gold: #e7d8ac;
$grey: #e1e1e1;

$proxima:"proxima-nova",sans-serif;
$proximaCondensed:"proxima-nova-extra-condensed",sans-serif;

$ice:url('http://assets.ngin.com/site_files/8917/images/ice.jpg') repeat;




