/*delete me*/
@keyframes in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// body:before {
// 	content: 'CSS is hooked up';
// 	position:fixed;
//   padding: 1em;
//   background: rgb(48, 140, 57);
//   z-index: 999;
//   color: white;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
//   bottom: 10px;
//   left: 10px;
//   opacity: 0;
//   animation: in .75s ease-in-out;
//   animation-delay: 1s;
//   animation-fill-mode: forwards;
//
// }
@import "variables";

@mixin aspect-ratio($ratio-or-width, $height: null) {
  $padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
  &:before { content:''; float: left; padding-bottom: $padding;  }
  &:after  { content:''; display: table; clear: both;  }
}

//
.black-bg{
  background-color: #000;
  .innerLayout{
    padding: 60px 15px !important;
  }
}

.style-cta{
  .pageElement{
    margin-bottom: 0;
  }
  .sn-call-to-action{
    height: auto !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    img{
      display: none;
    }
  }
  .sn-call-to-action-overlay{
    position: relative;
    top:auto;
    right:auto;
    bottom:auto;
    left:auto;
    // @include aspect-ratio(5,4);
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and(min-width: 769px){
      min-height: 230px;
    }
    padding: 40px 20px;
    background-color: rgba($yellow,0);
    transition: background-color .2s linear;
    &:hover{
      background-color: rgba($yellow,.75);
    }
  }
  .sn-call-to-action-overlay-inner{
    display: block;
  }
  .sn-call-to-action-overlay-text{
    background-color: rgba(0,0,0,.8);
    display: block;
    padding: 15px;
    p:last-of-type{
      margin-bottom: 0;
    }
  }
  .sn-call-to-action-title {
      font: 300 3em/1em "proxima-nova-extra-condensed",sans-serif;
      color: #FFF;
      text-transform: uppercase;
      letter-spacing: 1px;

  }
  .sn-call-to-action .sn-call-to-action-subtitle a,
  .sn-call-to-action .sn-call-to-action-subtitle span{
    color: #ffbb30;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: .05em;
  }
}


//
.grid-five{
  .edit_mode &{
    .columnBounds{
      display: flex;
      flex-flow: row wrap;
      .addPageElementTop{
        width: 100%;
      }
      .drag{
        flex-basis: 20%;
      }
    }
  }
  .user_mode &{
    .column{
      display: flex;
      flex-flow: row wrap;
      .pageEl{
        flex-basis: 20%;
        margin: 0;
        .heroPhotoElement{
          margin:0;
        }
      }
    }
  }
}


//
.custom-newsletter{
  .sn-call-to-action{
    background-color: #ffc423;
    overflow: hidden;
  }
  img{
    display: none;
  }
  .sn-call-to-action-overlay{
    position: relative;
    top:auto;
    right:auto;
    bottom:auto;
    left:auto;
  }
  .sn-call-to-action-overlay-inner{
    display: block;
  }
  .sn-call-to-action-overlay-text{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sn-call-to-action-title{
    order: 2;
    margin: 1em 0;
    max-width: 235px;
    width: 100%;
    height: 70px;
    background-image: url(../images/envelopes.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .sn-call-to-action-subtitle{
    font: 500 3em/1em "proxima-nova-extra-condensed",sans-serif;
    font-style: italic;
    text-transform: uppercase;
    letter-spacing: .05em;
    width: 100%;
    margin: 0;
    position: relative;
    span{
      color: #000;
      z-index: 3;
      position: relative;
      background-color: #ffc423;
      padding: 0 5%;
    }
    &:before{
      content: "";
      width: 100%;
      padding: 0 21px;
      height: 2px;
      background-color: #FFF;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
    }
    &:first-of-type{order:1;}
    &:last-of-type{order:3;}
  }
}




// Home Slider

// Custom Feature Slideshow

.home-slideshow{
  .newsSlideShow{
    margin:0 !important;
  }
  .newsSlideShow-thumbnails, .newsSlideShow-more, .newsSlideShow-controls{display: none;}
  .newsSlideShow-container{
    @include aspect-ratio(3,1);
    height: auto!important;
    box-shadow: none;
    min-height: 480px;

  }
  // Headline
  .newsSlideShow-container{
    .newsSlideShow-headline{
      transform: skew(-15deg, 0deg);
      background-color: rgba(0,0,0,.85);
      margin: 0;
      margin-bottom: 45px;
      padding: 25px 5px 25px 50px !important;
      width: 90%;
      max-width: 800px;
      margin-left: 3em;
      margin-right: 3em;
      h1, p{
        background: none;
        text-align: left;
        padding: 0;
        height: auto;
        font-family: $proximaCondensed;
        // font-style: italic;
        font-weight: 500;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      h1{
        font-size: 46px;

        a{
          height: auto;
          color: $yellow;
        }
      }
      p{
        font-size: 29px;

        span{
          float: none;
          padding: 0;
        }
      }
    }
    .newsSlideShow-article{
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      img{
        top: 50% !important;
        left: 50% !important;
        transform: translateX(-50%) translateY(-50%);
        display: none;
      }
    }
  }
}


//

.penLink .pageElement.linkElement h4 a:before{
  content: "+";
}



// Top Nav
#siteContainer2 #topNav table tr td#homeLink a,
#siteContainer2.one_panel #topNav table tr td#homeLink a,
#siteContainer2.two_panel #topNav table tr td#homeLink a{
  height: 10px;
}
#siteContainer2 #topNav table tr td#homeLink,
#siteContainer2.one_panel #topNav table tr td#homeLink,
#siteContainer2.two_panel #topNav table tr td#homeLink{
    padding: 15px 0;
}

#siteContainer2 #topNav table tr td,
#siteContainer2.one_panel #topNav table tr td,
#siteContainer2.two_panel #topNav table tr td{
    padding: 0;
}
#siteContainer2 #homeLink,
#upmcLink{
  display: none;
}
#siteContainer2 #topNav table tr td>a,
#siteContainer2.one_panel #topNav table tr td>a,
#siteContainer2.two_panel #topNav table tr td>a{
  display: block;
      padding: 15px 0;
      background-color: $yellow;
      font: 400 1.5em/1em $proximaCondensed;

}
#siteContainer2.one_panel #topNav,
#siteContainer2.two_panel #topNav{
  table{
    tr{
      td{
        > a{
          color: #000;
        }
        &:hover, &.hover, &.over, &.selected{
          > a{
            color: #000;
          }
        }
      }
    }
  }
}
#siteContainer2 #topNav table tr td ul.dropdown,
#siteContainer2.one_panel #topNav table tr td ul.dropdown,
#siteContainer2.two_panel #topNav table tr td ul.dropdown{
  margin-top: 0;
}


#siteContainer2 #topNav table tr td.selected>a,
#siteContainer2.one_panel #topNav table tr td.selected>a,
#siteContainer2.two_panel #topNav table tr td.selected>a{
  &:before, &:after{
    content:"";
  }

}




#siteContainer2 #topNav td.enabled{
  position: relative;
  z-index: 1;
}
.edit_mode #siteContainer2 #topNav .enabled:nth-last-of-type(2),
.logged_in.user_mode #siteContainer2 #topNav .enabled:nth-last-of-type(3){
  .dropdown{
    right: 1px;
  }
}

@media screen and(min-width: 990px){
  .edit_mode #siteContainer2 #topNav td.enabled:nth-last-of-type(2):after,
  #home.logged_in.user_mode #siteContainer2 #topNav td.enabled:nth-last-of-type(4):after,
  :not(#home).logged_in.user_mode #siteContainer2 #topNav td.enabled:nth-last-of-type(3):after,
  :not(.logged_in).user_mode #siteContainer2 #topNav td.enabled:nth-last-of-type(3):after
  // #user_mode #top_nav_node_page_node_1923313:after,
  // #edit_mode .lastNavElement:after,
  {
      content: "";
      display: table-cell;
      width: 50px;
      height: 100%;
      background-color: $yellow;
      position: absolute;
      top: 0;
      right: -30%;
      z-index: -1;
      transform: skew(-25deg);
  }
}
@media screen and(max-width: 989px){
  #siteContainer2.one_panel #topNav table,
  #siteContainer2.two_panel #topNav table{
    width: 100%;
  }
}
#siteContainer2 #topNav{
  z-index: 100;
  position: relative;
  table:before{
    content: "";
    display: block;
    width:50%;
    height: 46px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $yellow;
  }
}


// Site Header

.nav-header{
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  .nh-logo{
    width: 350px;
    height: 60px;
    display: block;
    margin-right: auto;
    background: url(../images/nh-logo.png) no-repeat center;
    background-size: contain;
  }
  .search{
    width: 240px;
    height: 30px;
    display: block;
    background-color: #232323;
    margin: 0 15px;
  }
  .social-media{
    display: block;
  }
}




// Social Media
.social-custom .sn-social-media-list{
  .sn-social-media-icon{
    font-size: 1.35em;
    background: transparent;
    background: none;
    border-radius: 100%;
    border: 2px solid #FFF;
    width: 38px;
    height: 38px;
    line-height: 1.6;
    &:hover{
      color: $yellow;
      border-color: $yellow;
      background: none;
    }
  }
  .icon-youtube{
    &:before{
      content: "\f16a";
    }
  }
}
.social-media{
  .social-custom{
    > div {margin:0;}
  }
}

// footerHead
#siteFooter>ul .megaFooter .footerInfo{
  padding: 0 20px;
}
.newFooter{
  #layoutsBin &{
    background-color: #FDFDFD;
  }
}
.footerContact, .footerLinks{
  display: none !important;
}
.footer-text{
  .text{

    h4{
      text-transform: uppercase;
      color: #FFF;
      -webkit-font-smoothing: antialiased;
      letter-spacing: 0;
    }
    > p{
      color: #FFF;
      margin-bottom: 10px;
    }
    > div{
        background-color: $yellow;
        color: #000;
        padding: 10px;
        display: inline-block;
        margin-bottom: 10px;
    }
  }
}
.footer-text-address{
  .text{
    p:first-of-type{
      margin-left: 30px;
      position: relative;
      line-height: 1.2;
      &:before{
        color: $yellow;
        content: "\f041";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        top: 1px;
        left: -30px;
        font-size: 36px;
        line-height: 1;
      }
    }
  }
}
.footer-text-links{
  .text{
    p{
      margin:0;
      text-transform: capitalize;
    }
    a{
      color: #FFF;
      &:hover{
        color: $yellow;
      }
    }
  }
}


// Custom search
.search{
  td{
    display: block;
  }
}
#navLinksKill{
  height: 30px !important;
  background: none;
}
#navLinksLink{
  background: none;
  &, span{
    display: block;
    color: rgba(#FFF, .6);
    // transition: color .2s linear;
    // .over &{
    //   color: rgba(#FFF, 1);
    // }
  }
}
#navLinksLink, #navLinksKill{
  span span{
    background: none;
    padding: 0;
    display: flex;
    height: 30px;
    padding-left: 10px;
    font-size: 0;
    &:before{
      content: "\f002";
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 16px;
      padding-top: 6px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
#navLinksContainer{
  box-shadow: none;
  font-family: $proxima;
}
#navLinksForm, #navLinksResults{
  transform: translateY(-30px);
}
#navLinksForm{
  padding: 5px;
  background: none;
  input{
    width:100%;
    background-color: #232323;
    color: #FFF;
    font-family: $proxima;

    &:focus{
      background-color: #232323;
      box-shadow: none;
      border: none;
      // border-bottom: 1px solid #111;
      outline:none;
    }
  }
}
#navLinksResults{
  // display: flex;
  // flex-direction: column-reverse;
  background: #DFDFDF;
  border-bottom: none;
  li a{
    background: #232323;
    border-radius: none;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
    border-top: 1px solid #FFF;
    color: #FFF;
    padding: 5px 5px;
    b{color: $yellow;}
    small{color: #FFF;}
  }
  li.selected a{
    border-radius: none;
    background: $yellow;
    border: none;
    border-top: 1px solid #FFF;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    b{color: #232323;}
    small{color: #FFF;}
  }
}

#navLinksShowAll {
  margin: -1px 0 0;
  line-height: 1;
  background: #111;
  box-shadow: none;
  padding: 6px 10px;
}
@media screen and (max-width: 768px){
  #navLinksContainer, .search, .nav-header .search, .nav-header .nh-logo{
    display: none;
  }
  .nav-header{
    justify-content: center;
    padding: 10px 0;
  }
}



// Assets page
#top_nav_node_page_node_1903924{
  .user_mode &{
    display: none;
  }
}
.mobileTitle{
  min-height: 42px;
}

#siteFooter>ul .megaFooter #footerHead{
  background-size: contain;
  &:before{
    width: 100%;
    padding-left: 290px;
    background-clip: content-box;
    box-sizing: border-box;
  }
}


// Footer
@media only screen and (max-width: 48em){
  .megaFooter{
    display:block;
  }
  .footer-text-address {
    max-width: 280px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 620px) and (max-width: 48em){
  .newFooter{
    .column1{width:100%;}
    .column2, .column3{
      width: 50%;
    }
  }
}
@media only screen and (max-width: 619px){
  .newFooter{
    .column{width:100%;}
  }
}


@media only screen and (max-width: 48em){
  #menuBar{
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;

    .menuLogoBar{
      width: 100%;
      max-width: 64px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      order: -1;
      margin: 0 5px;
      flex-shrink: 2.5;
      display: none;
    }
    &:before{
      content: "";
      display: block;
      width: 100%;
      max-width: 230px;
      height: 40px;
      margin: 0 5px;
      background: url("../images/nh-logo.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    span{
      margin-left: auto;
      width: 50px;
    }
  }
  #mobileMenu .mobileHeader .mobileLogo{
    height: 5px;
    background: #FFF;
  }
}
